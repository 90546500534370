import { Link } from 'react-router-dom';
import { FaInstagram, FaDiscord, FaTwitter, FaYoutube, FaTeamspeak, FaPatreon } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="relative bg-base-300 text-base-content p-10">
            {/* Centered container for the footer content */}
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-8 w-full max-w-screen-xl relative">
                
                {/* Left Section - Rechtliches (stacked links) */}
                <nav className="footer-left flex flex-col items-center md:items-start">
                    <h6 className="footer-title">Rechtliches</h6>
                    <Link to='/imprint' className="link link-hover">Impressum</Link>
                    <Link to='/privacy' className="link link-hover">Datenschutzerklärung</Link>
                </nav>
                
                {/* Center Section - Social Links */}
                <nav className="footer-center flex flex-col items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
                    <h6 className="footer-title text-center">Social</h6>
                    <div className="flex justify-center gap-4 mt-2">
                        <Link to='https://discordapp.com/invite/DpJc83tKh2' target='_blank'><FaDiscord size={30} className="hover:text-[#7289da]" /></Link>
                        <Link to='https://twitter.com/TTVMoshpit' target='_blank'><FaTwitter size={30} className="hover:text-[#1DA1F2]"/></Link>
                        <Link to='https://youtube.com/@jamielul' target='_blank'><FaYoutube size={30} className="hover:text-[#FF0000]"/></Link>
                        <Link to='https://instagram.com/ttvmoshpit' target='_blank'><FaInstagram size={30} className="hover:text-[#C13584]"/></Link>
                        <Link to='ts3server://185.223.30.27?port=9987' target='_blank'><FaTeamspeak size={30} className="hover:text-[#2580c3]"/></Link>
                        <Link to='https://www.patreon.com/TTVMoshpit' target='_blank'><FaPatreon size={30} className="hover:text-[#F96854]"/></Link>
                    </div>
                </nav>
                
                {/* Right Section - IT-Recht Kanzlei Logo */}
                <nav className="footer-right flex justify-center md:justify-end w-full md:w-auto">
                    <Link to="https://www.it-recht-kanzlei.de/" target="_blank">
                        <img src="https://www.it-recht-kanzlei.de/logo/Logo_Juristisch_betreut_durch_IT-Recht_Kanzlei.png?i=524c9-38c4b-39c6-907c-1" alt="Vertreten durch die IT-Recht Kanzlei" className="w-40 md:w-32 lg:w-[100%]" />
                    </Link>
                </nav>
            </div>
        </footer>
    );
}

export default Footer;
