import { useEffect, useState } from 'react';

const NewsItem = ({ title, text, publishedAt, creator, imageUrl }) => (
    <div className="blog-post my-6 p-6 bg-base-300 shadow-md rounded-md mb-8">
        {imageUrl && (
            <img src={imageUrl} alt={title} className="w-full object-cover rounded-md mb-2" />
        )}
        <p className="text-lg text-secondary mb-1 font-bold">{new Date(publishedAt).toLocaleDateString()}</p>
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        <p className="text-lg mb-3 leading-relaxed">{text}</p>
        <p className="text-lg font-bold text-secondary">~ {creator}</p>
    </div>
);

const NewsList = () => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const limit = 3;

    const fetchInitialPosts = async () => {
        try {
            const response = await fetch(
                `https://admin.ttv-moshpit.de/api/blogs?sort[0]=publishedAt:desc&pagination[page]=1&pagination[pageSize]=${limit}&populate=*`
            );
            if (!response.ok) throw new Error(`Error: ${response.status}`);
            const data = await response.json();

            if (data && data.data) {
                const initialPosts = data.data.map((post) => ({
                    title: post.attributes.Title,
                    text: post.attributes.Text,
                    publishedAt: post.attributes.publishedAt,
                    creator: post.attributes.Ersteller || 'Unknown',
                    imageUrl: post.attributes.Bild?.data?.attributes?.url
                        ? `https://admin.ttv-moshpit.de${post.attributes.Bild.data.attributes.url}`
                        : '',
                }));

                setPosts(initialPosts);
                if (initialPosts.length < limit) setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching posts:', error.message);
        }
    };

    const fetchMorePosts = async (currentPage) => {
        try {
            const response = await fetch(
                `https://admin.ttv-moshpit.de/api/blogs?sort[0]=publishedAt:desc&pagination[page]=${currentPage}&pagination[pageSize]=${limit}&populate=*`
            );
            if (!response.ok) throw new Error(`Error: ${response.status}`);
            const data = await response.json();

            if (data && data.data) {
                const newPosts = data.data.map((post) => ({
                    title: post.attributes.Title,
                    text: post.attributes.Text,
                    publishedAt: post.attributes.publishedAt,
                    creator: post.attributes.Ersteller || 'Unknown',
                    imageUrl: post.attributes.Bild?.data?.attributes?.url
                        ? `https://admin.ttv-moshpit.de${post.attributes.Bild.data.attributes.url}`
                        : '',
                }));

                if (newPosts.length < limit) {
                    setHasMore(false);
                }

                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (error) {
            console.error('Error fetching posts:', error.message);
        }
    };

    useEffect(() => {
        fetchInitialPosts();
    }, []);

    useEffect(() => {
        if (page > 1) {
            fetchMorePosts(page);
        }
    }, [page]);

    const loadMore = () => {
        if (hasMore) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <div className="flex flex-col items-center p-6">
            <h1 className="text-5xl font-bold text-center mt-2 mb-2">NEWS</h1>

            <div className="w-full max-w-screen-xl">
                <div className="container mx-auto">
                    {/* Consistent divider below the "NEWS" heading */}
                    <div className="divider"></div>
                    
                    {posts.map((post, index) => (
                        <div key={index}>
                            <NewsItem
                                title={post.title}
                                text={post.text}
                                publishedAt={post.publishedAt}
                                creator={post.creator}
                                imageUrl={post.imageUrl}
                            />
                            {/* Divider between posts */}
                            {index < posts.length - 1 && <div className="divider my-6"></div>}
                        </div>
                    ))}

                    {hasMore && (
                        <div className="flex justify-center mt-8">
                            <button className="btn bg-secondary hover:bg-base-200" onClick={loadMore}>
                                Mehr anzeigen
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewsList;
