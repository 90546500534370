import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const PasswordProtectedForm = () => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [streamerName, setStreamerName] = useState('');
  const [communityPlayers, setCommunityPlayers] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  // Passwort, das überprüft wird
  const correctPassword = 'AHzk§~fpd8.uBW$Q';
  // API-Token zur Authentifizierung
  const API_TOKEN = 'a968b7ea5b26efb72e4e6eb9a4a04a59ecb63f54339d0a99ec9087c8bbc378ac410c4bc4a25e03fbc9ecf182cdc3b47c8c67096fc04318f8192d6df364cd4869b3e550ab0ef1fec3dc3c22bb656cb002a9d7d5b1f8b2bb4ca05ac8a649c5f0decf1294a326b1c869ff55df8f4c6d47da8b208ffae52fe84adb2d576305e1f929';

  useEffect(() => {
    // Prüfen, ob die Seite über einen bestimmten Pfad aufgerufen wurde
    if (location.pathname !== '/streamer') {
      navigate('/');
    }
  }, [location, navigate]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Falsches Passwort! Bitte versuche es erneut.');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!streamerName || !communityPlayers) {
      alert('Bitte fülle alle Felder aus, bevor du das Formular absendest.');
      return;
    }
    try {
      // Prüfen, ob der Streamer-Name bereits existiert
      const existingEntries = await axios.get(
        `https://admin.ttv-moshpit.de/api/streamer-communities?filters[StreamerName][$eq]=${encodeURIComponent(streamerName)}`,
        {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        }
      );

      if (existingEntries.data.data.length > 0) {
        alert('Der Streamer-Name wurde bereits eingetragen.');
        return;
      }

      // Sende Daten an die Strapi API mit Authentifizierungs-Token
      const response = await axios.post(
        'https://admin.ttv-moshpit.de/api/streamer-communities',
        {
          data: {
            StreamerName: streamerName, // Geändertes Feld auf 'StreamerName'
            CommunitySpieler: communityPlayers, // Geändertes Feld auf 'CommunitySpieler'
            publishedAt: null, // Setze publishedAt auf null, damit der Eintrag nicht veröffentlicht wird
          },
        },
        {
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert('Community Spieler erfolgreich eingetragen!');
        setStreamerName('');
        setCommunityPlayers('');
      } else {
        alert('Ein Fehler ist aufgetreten. Bitte versuche es erneut.');
      }
    } catch (error) {
      console.error('Fehler beim Eintragen der Daten:', error);
      alert('Ein Fehler ist aufgetreten. Bitte versuche es erneut.');
    }
  };

  return (
    <div className="min-h-screen p-6 w-full max-w-screen-xl mx-auto">
      <h1 className="text-5xl font-bold text-center mt-2 mb-8"> Community Formular</h1>
    <div className='flex flex-col items-center justify-center'>
      {!isAuthenticated ? (
        <div className="w-96 p-8 text-center shadow-lg rounded-md mx-auto bg-base-300">
          <h2 className="text-2xl font-bold mb-4">Passwort eingeben</h2>
          <form onSubmit={handlePasswordSubmit}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Passwort"
              className="w-full px-4 py-2 mb-4 border rounded focus:outline-none focus:ring"
            />
            <button
              type="submit"
              className="w-full py-2 px-4 bg-secondary hover:bg-base-200 rounded-md"
            >
              Bestätigen
            </button>
          </form>
        </div>
      ) : (
        <div className="w-96 p-8 text-center shadow-lg rounded-md mx-auto bg-base-300">
          <h2 className="text-2xl font-bold mb-4">Community Formular</h2>
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              value={streamerName}
              onChange={(e) => setStreamerName(e.target.value)}
              placeholder="Streamer Name"
              className="w-full px-4 py-2 mb-4 border rounded focus:outline-none focus:ring"
            />
            <textarea
              value={communityPlayers}
              onChange={(e) => setCommunityPlayers(e.target.value)}
              placeholder="Spieler der Community"
              className="w-full px-4 py-2 mb-4 border rounded focus:outline-none focus:ring"
            ></textarea>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-secondary hover:bg-base-200 rounded-md"
              disabled={!streamerName || !communityPlayers}
            >
              Absenden
            </button>
          </form>
          </div>
        
      )}
    </div>
    </div>
  );
};

export default PasswordProtectedForm;
