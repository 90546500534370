
const Privacy = () => {

    return (
        <div className="max-w-[100vw] px-6 pb-16 xl:pr-2">
            <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
                <div className="prose prose-sm md:prose-base w-full max-w-4xl flex-grow pt-10">
                <h1>Datenschutzerkl&auml;rung</h1>

      <h2>1) Einleitung und Kontaktdaten des Verantwortlichen</h2>
      <p><strong>1.1</strong> Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.</p>
      <p><strong>1.2</strong> Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Lennart Ehlers, c/o COCENTER, Koppoldstr. 1 86551 Aichach, Deutschland, Tel.: 015118981939, E-Mail: info@ttv-moshpit.de. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>

      <h2>2) Datenerfassung beim Besuch unserer Website</h2>
      <p><strong>2.1</strong> Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an den Seitenserver &uuml;bermittelt (sog. &bdquo;Server-Logfiles&#8220;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>
      <ul>
        <li>Unsere besuchte Website</li>
        <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>Menge der gesendeten Daten in Byte</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
      </ul>
      <p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>
      <p><strong>2.2</strong> Diese Website nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung personenbezogener Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge &bdquo;https://&#8220; und dem Schloss-Symbol in Ihrer Browserzeile erkennen.</p>

      <h2>3) Hosting &amp; Content-Delivery-Network</h2>
      <p>F&uuml;r das Hosting unserer Website und die Darstellung der Seiteninhalte nutzen wir einen Anbieter, der seine Leistungen selbst oder durch ausgew&auml;hlte Sub-Unternehmer ausschlie&szlig;lich auf Servern innerhalb der Europ&auml;ischen Union erbringt.</p>
      <p>S&auml;mtliche auf unserer Website erhobenen Daten werden auf diesen Servern verarbeitet.</p>
      <p>Wir haben mit dem Anbieter einen Auftragsverarbeitungsvertrag geschlossen, der den Schutz der Daten unserer Seitenbesucher sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.</p>

      <h2>4) Kontaktaufnahme</h2>
      <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet.</p>
      <p>Rechtsgrundlage f&uuml;r die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

      <h2>5) Seitenfunktionalit&auml;ten</h2>
      <p><strong>5.1</strong> Google Web Fonts</p>
      <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts des folgenden Anbieters: Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland</p>
      <p>Beim Aufruf einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen und stellt eine direkte Verbindung zu den Servern des Anbieters her. Hierbei werden bestimmte Browserinformationen, einschlie&szlig;lich Ihrer IP-Adresse, an den Anbieter &uuml;bermittelt.</p>
      <p>Daten k&ouml;nnen zudem &uuml;bermittelt werden an: Google LLC, USA</p>
      <p>Die Verarbeitung von personenbezogenen Daten im Zuge der Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur dann vollzogen, wenn Sie uns gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdr&uuml;ckliche Einwilligung erteilt haben. Sie k&ouml;nnen Ihre erteilte Einwilligung jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen, indem Sie diesen Dienst &uuml;ber das auf der Webseite bereitgestellte &bdquo;Cookie-Consent-Tool&#8220; deaktivieren. Falls Ihr Browser keine Web Fonts unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
      <p>F&uuml;r Daten&uuml;bermittlungen in die USA hat sich der Anbieter dem EU-US-Datenschutzrahmen (EU-US Data Privacy Framework) angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der Europ&auml;ischen Kommission die Einhaltung des europ&auml;ischen Datenschutzniveaus&nbsp;sicherstellt.</p>
      <p>Weitere Hinweise zu den Datenschutzbestimmungen von Google finden sich hier: <a href="https://business.safety.google/intl/de/privacy/" target="_blank" rel="noopener noreferrer">https://business.safety.google/intl/de/privacy/</a></p>

      <p><strong>5.2</strong> Online-Bewerbungen &uuml;ber ein Formular</p>
      <p>Auf unserer Website schreiben wir in einer gesonderten Rubrik aktuell vakante Stellen aus, auf die sich Interessenten &uuml;ber ein entsprechendes Formular bewerben k&ouml;nnen.</p>
      <p>Die Bewerber m&uuml;ssen alle personenbezogenen Daten angeben, die f&uuml;r eine fundierte Beurteilung erforderlich sind, einschlie&szlig;lich allgemeiner Informationen wie Name, Anschrift und Kontaktm&ouml;glichkeiten, sowie leistungsbezogene Nachweise und gegebenenfalls gesundheitsbezogene Angaben. Einzelheiten zur Bewerbung sind der Stellenausschreibung zu entnehmen.</p>
      <p>Im Zuge des Absendens des Formulars werden die Bewerberdaten entsprechend dem Stand der Technik verschl&uuml;sselt an uns &uuml;bermittelt, von uns gespeichert und ausschlie&szlig;lich zum Zwecke der Bewerbungsbearbeitung ausgewertet. Die Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (bzw. &sect; 26 Abs. 1 BDSG), in deren Sinne das Durchlaufen des Bewerbungsverfahrens als Arbeitsvertragsanbahnung gilt.</p>
      <p>Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten wie Angaben &uuml;ber die Schwerbehinderteneigenschaft) bei Bewerbern angefragt werden, erfolgt die Verarbeitung gem&auml;&szlig; Art. 9 Abs. 2 lit. b. DSGVO, damit wir die aus dem Arbeitsrecht und dem Recht der sozialen Sicherheit und des Sozialschutzes erwachsenden Rechte aus&uuml;ben und unseren diesbez&uuml;glichen Pflichten nachkommen k&ouml;nnen.</p>
      <p>Kumulativ oder alternativ kann die Verarbeitung der besonderen Datenkategorien auch auf Art. 9 Abs. 1 lit. h DSGVO gest&uuml;tzt sein, wenn sie zu Zwecken der Gesundheitsvorsorge oder der Arbeitsmedizin, f&uuml;r die Beurteilung der Arbeitsf&auml;higkeit des Bewerbers, f&uuml;r die medizinische Diagnostik, die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder f&uuml;r die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt.</p>
      <p>Kommt es nicht zu einer Auswahl des Bewerbers oder zieht ein Bewerber seine Bewerbung vorzeitig zur&uuml;ck, werden dessen formularm&auml;&szlig;ig &uuml;bermittelte Daten sowie s&auml;mtlicher elektronischer Schriftverkehr einschlie&szlig;lich der Bewerbungsmail nach einer entsprechenden Benachrichtigung sp&auml;testens nach 6 Monaten gel&ouml;scht. Diese Frist bemisst sich nach unserem berechtigten Interesse, etwaige Anschlussfragen zu der Bewerbung zu beantworten und gegebenenfalls unseren Nachweispflichten aus den Vorschriften zur Gleichbehandlung von Bewerbern nachkommen zu k&ouml;nnen.</p>
      <p>Im Falle einer erfolgreichen Bewerbung werden die zur Verf&uuml;gung gestellten Daten auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (bei Verarbeitung in Deutschland i.V.m. &sect; 26 Abs. 1 BDSG) zum Zwecke der Durchf&uuml;hrung des Besch&auml;ftigungsverh&auml;ltnisses verarbeitet.</p>

      <h2>6) Tools und Sonstiges</h2>
      <p><strong>Cookie-Consent-Tool</strong></p>
      <p>Diese Website nutzt zur Einholung wirksamer Nutzereinwilligungen f&uuml;r einwilligungspflichtige Cookies und cookie-basierte Anwendungen ein sog. &bdquo;Cookie-Consent-Tool&#8220;. Das &bdquo;Cookie-Consent-Tool&#8220; wird Nutzern bei Seitenaufruf in Form einer interaktiven Benutzeroberfl&auml;che angezeigt, auf welcher sich per H&auml;kchensetzung Einwilligungen f&uuml;r bestimmte Cookies und/oder cookie-basierte Anwendungen erteilen lassen. Hierbei werden durch den Einsatz des Tools alle einwilligungspflichtigen Cookies/Dienste nur dann geladen, wenn der jeweilige Nutzer entsprechende Einwilligungen per H&auml;kchensetzung erteilt. So wird sichergestellt, dass nur im Falle einer erteilten Einwilligung derartige Cookies auf dem jeweiligen Endger&auml;t des Nutzers gesetzt werden.</p>
      <p>Das Tool setzt technisch notwendige Cookies, um Ihre Cookie-Pr&auml;ferenzen zu speichern. Personenbezogene Nutzerdaten werden hierbei grunds&auml;tzlich nicht verarbeitet.</p>
      <p>Kommt es im Einzelfall zum Zwecke der Speicherung, Zuordnung oder Protokollierung von Cookie-Einstellungen doch zur Verarbeitung personenbezogener Daten (wie etwa der IP-Adresse), erfolgt diese gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einem rechtskonformen, nutzerspezifischen und nutzerfreundlichen Einwilligungsmanagement f&uuml;r Cookies und mithin an einer rechtskonformen Ausgestaltung unseres Internetauftritts.</p>
      <p>Weitere Rechtsgrundlage f&uuml;r die Verarbeitung ist ferner Art. 6 Abs. 1 lit. c DSGVO. Wir unterliegen als Verantwortliche der rechtlichen Verpflichtung, den Einsatz technisch nicht notwendiger Cookies von der jeweiligen Nutzereinwilligung abh&auml;ngig zu machen.</p>
      <p>Soweit erforderlich, haben wir mit dem Anbieter einen Auftragsverarbeitungsvertrag geschlossen, der den Schutz der Daten unserer Seitenbesucher sicherstellt und eine unberechtigte Weitergabe an Dritte untersagt.</p>
      <p>Weitere Informationen zum Betreiber und den Einstellungsm&ouml;glichkeiten des Cookie-Consent-Tools finden Sie direkt in der entsprechenden Benutzeroberfl&auml;che auf unserer Website.</p>

      <h2>7) Rechte des Betroffenen</h2>
      <p><strong>7.1</strong> Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte (Auskunfts- und Interventionsrechte), wobei f&uuml;r die jeweiligen Aus&uuml;bungsvoraussetzungen auf die angef&uuml;hrte Rechtsgrundlage verwiesen wird:</p>
      <ul>
        <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO;</li>
        <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO;</li>
        <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO;</li>
        <li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO;</li>
        <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO;</li>
        <li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO;</li>
        <li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO;</li>
        <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO.</li>
      </ul>
      <p><strong>7.2</strong> WIDERSPRUCHSRECHT</p>
      <p>WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.</p>
      <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.</p>
      <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>
      <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>

      <h2>8) Dauer der Speicherung personenbezogener Daten</h2>
      <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).</p>
      <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO werden die betroffenen Daten so lange gespeichert, bis Sie Ihre Einwilligung widerrufen.</p>
      <p>Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im Rahmen rechtsgesch&auml;ftlicher bzw. rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>
      <p>Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO aus&uuml;ben, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</p>
      <p>Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;ben.</p>
      <p>Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung &uuml;ber spezifische Verarbeitungssituationen nichts anderes ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.</p>
                
                </div>
            </div>
        </div>
    )
}

export default Privacy;