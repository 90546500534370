
const Impress = () => {

    return (
        <div className="max-w-[100vw] px-6 pb-16 xl:pr-2">
            <div className="flex flex-col-reverse justify-between gap-6 xl:flex-row">
                <div className="prose prose-sm md:prose-base w-full max-w-4xl flex-grow pt-10">
                    <h1 className="text-[2.25rem]">Impressum</h1>
                    <p>
                        Lennart Ehlers<br />
                        c/o COCENTER<br />
                        Koppoldstr. 1 86551 Aichach<br />
                        Deutschland<br />
                        <br />
                        Tel.: 015118981939<br />
                        E-Mail: info@ttv-moshpit.de<br />
                        <br />
                        Verantwortliche/r i.S.d. § 18 Abs. 2 MStV:<br />
                        Lennart Ehlers, c/o COCENTER, Koppoldstr. 1, 86551 Aichach<br />
                        <br />
                        Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr" className="link link-info">https://ec.europa.eu/odr</a><br />
                        <br />
                        Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Impress;