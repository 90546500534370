import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaGlobe, FaTwitch, FaTwitter, FaInstagram } from 'react-icons/fa';

const Partner = () => {
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchPartners = async () => {
        try {
            const response = await axios.get('https://admin.ttv-moshpit.de/api/partners?populate=*');
            const responseData = response.data;

            if (responseData && responseData.data) {
                const partnerData = responseData.data
                    .map((partner) => ({
                        id: partner.id,
                        name: partner.attributes.Name,
                        description: partner.attributes.Beschreibung,
                        imageUrl: partner.attributes.Bild?.data?.attributes?.url
                            ? `https://admin.ttv-moshpit.de${partner.attributes.Bild.data.attributes.url}`
                            : '',
                        socials: partner.attributes.Socials,
                        sortOrder: partner.attributes.Sortierung || 0, // Assuming Sortierung is a numeric field
                    }))
                    .sort((a, b) => a.sortOrder - b.sortOrder); // Sort partners by Sortierung value

                setPartners(partnerData);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching partners:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPartners();
    }, []);

    return (
        <div className="p-6 w-full max-w-screen-xl mx-auto">
            <h1 className="text-5xl font-bold text-center mt-2 mb-8">PARTNER</h1>

            {loading ? (
                <p className="text-center text-gray-400">Lädt...</p>
            ) : (
                <div className="flex flex-wrap justify-center gap-6">
                    {partners.map((partner) => (
                        <div key={partner.id} className="w-60 p-4 text-center shadow-lg rounded-md">
                            <div className="flex justify-center items-center mb-3">
                                {partner.imageUrl && (
                                    <img 
                                        src={partner.imageUrl} 
                                        alt={partner.name} 
                                        className="w-24 h-24 object-cover rounded-full"
                                    />
                                )}
                            </div>
                            <h4 className="text-lg font-bold mb-2">{partner.name}</h4>
                            <p className="text-lg text-secondary mb-4">{partner.description}</p>
                            <div className="flex justify-center gap-4 mt-4">
  {partner.socials?.website && (
    <a
      href={partner.socials.website}
      target="_blank"
      rel="noopener noreferrer"
      className="transform transition-transform duration-300 ease-in-out hover:scale-125 hover:rotate-6 hover:text-yellow-400"
    >
      <FaGlobe className="text-white-800 text-2xl" />
    </a>
  )}
  {partner.socials?.twitch && (
    <a
      href={partner.socials.twitch}
      target="_blank"
      rel="noopener noreferrer"
      className="transform transition-transform duration-300 ease-in-out hover:scale-125 hover:rotate-6 hover:text-yellow-400"
    >
      <FaTwitch className="text-purple-600 text-2xl" />
    </a>
  )}
  {partner.socials?.twitter && (
    <a
      href={partner.socials.twitter}
      target="_blank"
      rel="noopener noreferrer"
      className="transform transition-transform duration-300 ease-in-out hover:scale-125 hover:rotate-6 hover:text-yellow-400"
    >
      <FaTwitter className="text-blue-400 text-2xl" />
    </a>
  )}
  {partner.socials?.instagram && (
    <a
      href={partner.socials.instagram}
      target="_blank"
      rel="noopener noreferrer"
      className="transform transition-transform duration-300 ease-in-out hover:scale-125 hover:rotate-6 hover:text-yellow-400"
    >
      <FaInstagram className="text-pink-600 text-2xl" />
    </a>
  )}
</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Partner;
