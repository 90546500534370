import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark, FaChevronDown } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import logo from "../assets/logo.png";
import MinecraftStatus from "./MinecraftStatus";
import axios from "axios";

const Header = () => {
  const [theme, setTheme] = useState("dim");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [areas, setAreas] = useState([]);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleTheme = () => {
    setTheme(theme === "dim" ? "nord" : "dim");
  };

  useEffect(() => {
    document.querySelector("html").setAttribute("data-theme", theme);
  }, [theme]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await axios.get("https://admin.ttv-moshpit.de/api/bereiches");
        const responseData = response.data;

        if (responseData && responseData.data) {
          const areaData = responseData.data.map((area) => ({
            name: area.attributes.Name,
            status: area.attributes.Status, 
          }));
          setAreas(areaData);
        }
      } catch (error) {
        console.error("Error fetching area data:", error.message);
      }
    };

    fetchAreas();
  }, []);

  return (
    <div className="navbar bg-base-300 shadow-md p-4 relative">
      {/* Navbar Links*/}
      <div className="navbar-start flex items-center">
        {/* Menu Mobile */}
        <button
          onClick={toggleMenu}
          className="btn btn-ghost lg:hidden"
          aria-label="Toggle Menu"
        >
          <FaBars className="h-6 w-6" />
        </button>

        {/* Navbar Desktop */}
        <div className="hidden lg:flex lg:items-center lg:gap-6 font-bold">
          <ul className="menu menu-horizontal">
            <li className="transition-colors">
              <Link to="/">HOME</Link>
            </li>
            <li className="transition-colors">
              <Link to="/partner">PARTNER</Link>
            </li>
            <li className="transition-colors">
              <Link to="/Team">TEAM</Link>
            </li>
            <li className="transition-colors">
              <Link to="/News">NEWS</Link>
            </li>
            <li className="transition-colors">
              <Link to="/Downloads">DOWNLOADS</Link>
            </li>
            <li
              className="transition-colors relative"
              ref={dropdownRef}
            >
              <button
                className="font-bold cursor-pointer flex items-center gap-2"
                onClick={toggleDropdown}
              >
                BEWERBUNG
                <FaChevronDown
                  className={`transition-transform duration-300 ${
                    isDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
              {isDropdownOpen && (
                <ul className="p-2 shadow-md bg-base-100 rounded-box absolute z-50 top-full left-0 mt-2">
                  {areas.map((area, index) => (
                    <li key={index}>
                      <Link
                        to={`/${area.name}`}
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        {area.name.toUpperCase()}
                        <div
                          className={`badge gap-2 ml-2 ${
                            area.status === "OPEN"
                              ? "badge-success rounded-md"
                              : "badge-error"
                          }`}
                        >
                          {area.status === "OPEN" ? (
                            <>
                              <FaCheckCircle />
                              OPEN
                            </>
                          ) : (
                            <>
                              <FaCircleXmark />
                              CLOSED
                            </>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>

      {/*Logo*/}
<div className="navbar-center absolute left-1/2 transform -translate-x-1/2">
  <Link to="/">
    <img src={logo} alt="Logo" className="w-16 h-16 cursor-pointer" />
  </Link>
</div>

      {/*Minecraft Status und Theme */}
      <div className="navbar-end gap-4 items-center">
        <MinecraftStatus />
        <label className="swap swap-rotate p-2 cursor-pointer">
          <input type="checkbox" onClick={toggleTheme} />

          {/* Sonne */}
          <svg
            className="swap-on h-8 w-8 fill-current text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
          </svg>

          {/* Mond */}
          <svg
            className="swap-off h-8 w-8 fill-current text-blue-800"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
          </svg>
        </label>
      </div>

      {/* navbar Mobile*/}
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute top-full left-0 w-full bg-base-100 z-20 p-4 shadow-md"
        >
          <ul className="menu menu-vertical gap-4 font-bold">
            <li className="transition-colors">
              <Link to="/" onClick={() => setIsMenuOpen(false)}>
                HOME
              </Link>
            </li>
            <li className="transition-colors">
              <Link to="/partner" onClick={() => setIsMenuOpen(false)}>
                PARTNER
              </Link>
            </li>
            <li className="transition-colors">
              <Link to="/Team" onClick={() => setIsMenuOpen(false)}>
                TEAM
              </Link>
            </li>
            <li className="transition-colors">
              <Link to="/News" onClick={() => setIsMenuOpen(false)}>
                NEWS
              </Link>
            </li>
            <li className="transition-colors">
              <Link to="/Downloads" onClick={() => setIsMenuOpen(false)}>
                DOWNLOADS
              </Link>
            </li>
            <li className="transition-colors">
              <details>
                <summary>BEWERBUNG</summary>
                <ul className="p-2 shadow-md bg-base-100 rounded-box">
                  {areas.map((area, index) => (
                    <li key={index}>
                      <Link to={`/${area.name}`} onClick={() => setIsMenuOpen(false)}>
                        {area.name.toUpperCase()}
                        <div
                          className={`badge gap-2 ml-2 ${
                            area.status === "OPEN" ? "badge-success rounded-md" : "badge-error"
                          }`}
                        >
                          {area.status === "OPEN" ? (
                            <>
                              <FaCheckCircle />
                              OPEN
                            </>
                          ) : (
                            <>
                              <FaCircleXmark />
                              CLOSED
                            </>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </details>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
