import { useEffect, useState } from 'react';
const mcs = require('node-mcstatus');

const MinecraftServer = (props) => {

    const [online, setOnline] = useState(false);
    const [players, setPlayers] = useState(0);

    const host = 'MinecraftMoshpit.de';
    const port = 25565;
    const options = { query: true };

    mcs.statusJava(host, port, options)
    .then((result) => {
        setOnline(result.online);
        setPlayers(result.players.online);
    })
    .catch((error) => {
        console.error(error);
    })

    return (
        <div className="flex justify-center items-center">
                    <div className='flex-grow' >
                        {players} Spieler online
                    </div>
                </div>
    );
}

export default MinecraftServer;